import { useContext, useEffect, useState } from "react";
import { FragmentDefiner, useReadFragment } from "../../../GlobalHooks/useReadFragment";
import { useFilters } from "../Filters.hook";
import { ConfigStateContext } from "shared-components/Contexts/Configurations/context";
import { getOperationId } from "src/utils/Functions";
import { operation_type_id } from "shared-components/ViewFragments/SearchResults/Sider/TopBarFilters/options";

const FRAGMENT_OPERATIONTYPES_OPTIONS = new FragmentDefiner(
	"Filter",
	`
      id
      name
      options
`
);
export const FRAGMENT_OPERATIONTYPES = new FragmentDefiner(
	"Filters",
	`
    operationType {
      ${FRAGMENT_OPERATIONTYPES_OPTIONS.query()}
    }
`
);

const useOperationType = props => {
	const { country_code } = useContext(ConfigStateContext);
	const { filters, changeFilters } = useFilters();
	const {
		labeled = false,
		inputType = "select"
	} = props;
	const { loading, data, error } = useReadFragment(
		FRAGMENT_OPERATIONTYPES_OPTIONS,
		"operationType"
	);
	const [selectedValue, setSelectedValue] = useState("");
	const operationTypeIdByCountry = operation_type_id.filter(type => type.countries.includes(country_code ? country_code.toUpperCase() : ""));
    const operationTypeList = operationTypeIdByCountry.map((type) => ({ text: type.text, value: type.value, projects: type.projects }));

	const onChange = newOperation => {
		changeFilters({
			projects: {text: null, value: newOperation.projects},
			operation_type_id: newOperation
				? { value: newOperation.value, text: newOperation.text }
				: null,
		});
	};

	useEffect(() => {
	  	if (filters?.operation_type_id) {
			setSelectedValue(getOperationId(filters?.operation_type_id, filters?.projects))
		}
	}, [filters?.operation_type_id, filters?.projects]);

	return {
		loading: loading,
		error: error,
		data: {
			options: operationTypeList,
			value: selectedValue,
			onChange,
			customKey: "operation_type_id",
		},
		show: true,
		labeled,
		label: data?.name,
		inputType,
	};
};

export { useOperationType };
